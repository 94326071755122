import Link from 'next/link';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import styles from './index.module.less';
import RetroTitle from '@/components/Common/RetroTitle';
import type { MarketplaceNavItem } from '@/components/Layouts';
import { NavItem } from '@/components/Layouts';
import { AppSettings } from '@/shared/app-common';
import Image from 'next/image';
import { Button, Dropdown, MenuProps } from 'antd';
import { useUserProvider } from '@/context/UserProvider';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ga_logEvent } from '@/shared/ga';
import { EventName } from '@/shared/event-types';
// import ChangeLanguage from '@/components/Common/Language';
import React from 'react';

import dynamic from 'next/dynamic';

const MobileHeader = dynamic(() => import('@/components/Layouts/MobileHeader'), { ssr: false });


interface GlobalHeaderProps {
  navItems?: MarketplaceNavItem[];
  isMobile?: boolean;
  isStarData?: boolean;
}

const GlobalHeader = ({ navItems, isMobile, isStarData = false }: GlobalHeaderProps) => {
  const { onOpenLoginModal, onChangedLoginView, isLogin, onSignOut, updateLoginPath } = useUserProvider();
  const { t } = useTranslation();
  const router = useRouter();
  const [deferredPrompt, setDeferredPrompt] = useState<Event | null>(null);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e: Event) => {
      e.preventDefault();
      setDeferredPrompt(e);
    };
    window.addEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    return () => {
      window.removeEventListener('beforeinstallprompt', handleBeforeInstallPrompt);
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      const promptEvent = deferredPrompt as any;
      promptEvent.prompt();

      // 等待用户回应
      const choiceResult = await promptEvent.userChoice;
      // if (choiceResult.outcome === 'accepted') {
      //   console.log('用户接受了PWA安装');
      // } else {
      //   console.log('用户拒绝了PWA安装');
      // }
      setDeferredPrompt(null); // 安装提示触发后，重置
    }
  };

  const UnloginHeader = useCallback(() => {
    return (
      isLogin ? <></> : <div className={styles.unLogin}>
        <span>{t('Get the latest site changes instantly —')}</span>
        <span onClick={() => {
          onOpenLoginModal(true);
        }}>
          {t('Join Now!')}
        </span>
      </div>
    );
  }, [isLogin]);

  const items: MenuProps['items'] = deferredPrompt ? [
    // {
    //   key: 'settings',
    //   label: (
    //     <Link
    //       href={'/dashboard/settings'}
    //       onClick={() => {
    //         ga_logEvent(EventName.header_Settings);
    //       }}
    //     >
    //       {t('Settings')}
    //     </Link>
    //   ),
    // },
    // {
    //   key: 'billing-records',
    //   label: (
    //     <Link
    //       href={'/billing-records'}
    //       onClick={() => {
    //         ga_logEvent(EventName.header_Payment_History);
    //       }}
    //     >
    //       {t('PaymentHistory')}
    //     </Link>
    //   ),
    // },
    {
      key: 'download-app',
      label: (
        <span
          onClick={handleInstallClick}
        >
          {t("Add to desktop")}
        </span>
      ),
    },
    {
      key: 'LogOut',
      label: (
        <Link
          href={'/'}
          onClick={() => {
            onSignOut();
            ga_logEvent(EventName.header_Logout);
          }}
        >
          {t('LogOut')}
        </Link>
      ),
    },
  ] : [
    // {
    //   key: 'settings',
    //   label: (
    //     <Link
    //       href={'/dashboard/settings'}
    //       onClick={() => {
    //         ga_logEvent(EventName.header_Settings);
    //       }}
    //     >
    //       {t('Settings')}
    //     </Link>
    //   ),
    // },
    // {
    //   key: 'billing-records',
    //   label: (
    //     <Link
    //       href={'/billing-records'}
    //       onClick={() => {
    //         ga_logEvent(EventName.header_Payment_History);
    //       }}
    //     >
    //       {t('PaymentHistory')}
    //     </Link>
    //   ),
    // },
    {
      key: 'LogOut',
      label: (
        <Link
          href={'/'}
          onClick={() => {
            onSignOut();
            ga_logEvent(EventName.header_Logout);
          }}
        >
          {t('LogOut')}
        </Link>
      ),
    },
  ]

  const getNavLeftSection = useMemo(() => {
    // if (!!isMarketplaceHome) {
    return (
      <div className={styles.navTitleContainer}>
        <div className={styles.catchPhrase}>
          <Link href="/">
            <RetroTitle
              text={
                <div style={{ height: '32px' }}>
                  <Image
                    className={styles.logo}
                    src={AppSettings.logoPath}
                    alt={AppSettings.name}
                    loading="lazy"
                    width={32}
                    height={32}
                  />
                  <img
                    src={AppSettings.namePath}
                    alt={AppSettings.name}
                    style={{ height: '32px', marginLeft: '0' }}
                  />
                  {!isStarData &&
                    <Image
                      src={'/images/layouts/collect-icon.webp'}
                      alt="collect-icon.webp"
                      width={32}
                      height={32}
                      style={{ borderRadius: '5px' }}
                    />
                  }
                </div>
              }
              // textColor="#ff401a"
              fontSize={28}
              showAlert={true}
            />
          </Link>
        </div>
      </div>
    );
  }, [isStarData]);

  const formatMenuData = useCallback(
    (navItems?: MarketplaceNavItem[]): MarketplaceNavItem[] => {
      if (isMobile) {
        return navItems?.reduce((acc: MarketplaceNavItem[], cur: MarketplaceNavItem) => {
          const tempItems = cur?.dropdownItems
            ? [...acc, { ...cur, dropdownItems: null }, ...cur?.dropdownItems]
            : [...acc, cur];
          return tempItems;
        }, []);

        // const uniqueGaNames = new Set<any>();
        // const uniqueData = tempList.filter((item) => {
        //   if (!uniqueGaNames.has(item?.gaName)) {
        //     uniqueGaNames.add(item?.gaName);
        //     return true;
        //   }
        //   return false;
        // });

        // return uniqueData;
      }
      return navItems;
    },
    [isMobile],
  );

  const renderNavRightSection = useMemo(() => {
    return formatMenuData(navItems)?.map((item, index) => (
      <NavItem
        item={item}
        key={index}
        className={classNames('', {
          'mobile-left-menu-item': isMobile,
        })}
      />
    ));
  }, [formatMenuData, isMobile, navItems]);

  const getLoginStateSection = useMemo(() => {
    return isLogin
      ? (
        <Dropdown menu={{ items }} placement="bottom" autoAdjustOverflow={false}>
          <Button
            className={styles.login}
            onClick={() => {
              router.push('/dashboard');
              ga_logEvent(EventName.header_Dashboard);
            }}
          >
            {t('Dashboard')}
          </Button>
        </Dropdown>
      )
      : (
        <Button
          className={styles.login}
          onClick={() => {
            updateLoginPath("/dashboard");
            onOpenLoginModal(true);
            onChangedLoginView(true);
            ga_logEvent(EventName.header_SignIn);
          }}
        >
          {t('SignIn_SignUp')}
        </Button>
      )
  }, [isLogin])

  if (isMobile) {
    return <MobileHeader>{renderNavRightSection}</MobileHeader>;
  }

  return (
    <div className={styles.headerContainer} style={{ position: isStarData ? 'absolute' : 'fixed', background: 'transparent' }}>
      {UnloginHeader()}
      <div
        className={classNames(styles.globalHeaderWarp)}
        style={{ background: isStarData ? 'transparent' : 'var(--foreground-rgb)' }}
      >
        <div className={styles.navSection}>{getNavLeftSection}</div>
        <div className={styles.navSection} style={{ justifyContent: 'center' }}>
          {renderNavRightSection}
        </div>

        <div className={styles.navSection}>
          {getLoginStateSection}
        </div>
      </div>
    </div>
  );
};


export default GlobalHeader;
